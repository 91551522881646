import axios from 'axios';
import { ref } from "vue";
import store from '@/store';
import useAlert from './useAlert';
import * as StreamSaver from 'streamsaver'

const alert = useAlert.salert

const myapiurl = "https://www.firesu.net";
// const myapiurl="";
// const pagename='碳硅锗兮'

//const myapiurl = "http://222.187.224.20:5001";
//const myapiurl="";
const pagename='碳硅锗兮'


//GetAPI
function getapi(api) {
    mytoken = (localStorage.getItem('jwtoken'));

    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'get',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'application/json;charset=UTF-8' },
            accept: 'application/json',
        }).then(res => {
            resolve(res);

        }).catch(function (err) {

            if (err.response) {
                var errcode = err.response.status;
                switch (errcode) {
                    case 401: { handler_401() }
                }

            }
            //reject (err)
        }).finally(() => {
            //store.commit('hideLoading')
        })
    }
    )
}

function getbaseapi(api) {
    mytoken = (localStorage.getItem('jwtoken'));

    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'get',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'application/json;charset=UTF-8' },
            accept: 'application/json',
            responseType: 'blob'
        }).then(res => {
            resolve(res);

        }).catch(function (err) {

            if (err.response) {
                var errcode = err.response.status;
                switch (errcode) {
                    case 401: { handler_401() }
                }

            }
            //reject (err)
        }).finally(() => {
            //store.commit('hideLoading')
        })
    }
    )
}



/**
 * @description 获取api数据
 * @param {string} api - 请求的接口
 * @returns {Promise} - 返回一个Promise对象
 */
function getapix(api) {
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios.get(requrl)
            .then(res => {
                resolve(res);
            })
            .catch(function (err) {
                if (err.response) {
                    var errcode = err.response.status;
                    switch (errcode) {
                        case 401: { handler_401() }
                    }
                }
                // reject(err)
            })
    }
    )
}

/**
 * 发送POST请求
 * @param {string} api - 请求的接口
 * @param {object} postdata - 请求的数据
 * @returns {Promise} 返回一个Promise对象
 */
function postapi(api, postdata = {}) {
    mytoken = (localStorage.getItem('jwtoken'));
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'post',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken },
            data: postdata
        }).then(res => {
            resolve(res);
        }).catch(function (err) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { handler_401() }
            }
        })
    }
    )
}




function updateapi(api, updatedata = {}) {
    mytoken = (localStorage.getItem('jwtoken'));
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'put',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken },
            data: updatedata
        }).then(res => {
            resolve(res);
        }).catch(function (err) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { warnning_401() }
            }
        })
    }
    )
}


function delapi(api) {
    mytoken = (localStorage.getItem('jwtoken'));
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'delete',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken }
        }).then(res => {
            resolve(res);
        }).catch(function (err) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { handler_401() }
            }
        })
    }
    )
}

function loginout() {

    localStorage.setItem('authed', false);
    localStorage.setItem('username', 'Login');
    localStorage.setItem('jwtoken', '');
    localStorage.setItem('role', '');
    console.log(localStorage.getItem('role'))
    store.commit('reloadLogin');
    islogin.value = false;
}

function handler_401() {

    loginout();
    window.location.href = '/login'
}

function jumpto(url) {
    window.location.href = url
}

function warnning_401() {

    loginout();
    useAlert.salert("您的签证已过期,请重新登录")
}


function register_user(logininfo) {
    if (logininfo.uPass1 == logininfo.uPass2) {
        postapi('firesu/free',
            {
                apiname: 'user_regist',
                data0: logininfo.uAccount,
                data1: logininfo.uPass1,
                data2: logininfo.uEmail,
                data3: logininfo.uEmailReply
            }
        ).then(response => {
            alert(response.data)
        })

    }
    else {
        alert("两次密码不一致");
    }
}

function get_myode(logininfo) {
    if (logininfo.uEmail && logininfo.uAccount) {
        if (logininfo.uPass1 == logininfo.uPass2) {
            postapi('firesu/free',
                {
                    apiname: 'get_ecode',
                    data0: logininfo.uAccount,
                    data1: logininfo.uEmail
                }
            ).then(response => {
                alert(response.data)
            })
        }
        else
            alert("两次输入的密码不一致")
    }
    else {
        alert("账号和邮箱不得为空");
    }

}

function uploadfile(api, postdata = {}) {
    console.log(api);
    mytoken = (localStorage.getItem('jwtoken'));
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'post',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'multipart/form-data' },
            data: postdata
        }).then(res => {
            resolve(res);
        }).catch(function (err) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { warnning_401() }
            }
        })
    }
    )
}





function downloadapi(api, downloadname) {
    mytoken = (localStorage.getItem('jwtoken'));

    const requrl = myapiurl +'/'+ api
    axios({
        method: 'get',
        url: requrl,
        headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'application/json;charset=UTF-8' },
        accept: 'application/json',
        responseType: 'blob'
    }).then(res => {
        const { data, headers } = res;
        const blob = new Blob([data], { type: headers['content-type'] })
        let dom = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        dom.href = url
        dom.target = '_blank'
        dom.download = downloadname
        dom.style.display = 'none'
        dom.click();
        // dom.parentNode.removeChild(dom);
        // window.URL.revokeObjectURL(url);    

    }).catch(function (err) {

        if (err.response) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { handler_401() }
            }

        }
        //reject (err)
    }).finally(() => {
        //store.commit('hideLoading')
    })


}

function downloadfetch(api, downloadname) {
    useAlert.salert("已经启动下载")
    mytoken = (localStorage.getItem('jwtoken'));

    const fileStream = StreamSaver.createWriteStream(downloadname)
    const requrl = myapiurl +'/'+ api
    fetch(requrl, {
        method: 'get',
        cache: 'no-cache',
        headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'application/json;charset=UTF-8' },
        accept: 'application/json',
        responseType: 'blob'
    }).then(res => {
        if (res.status == 200) {
            const readableStream = res.body
            if (window.WritableStream && readableStream.pipeTo) {
                return readableStream.pipeTo(fileStream).then(
                    () => {
                        console.log("download finished")
                    }
                )
            }
            window.writer = fileStream.getWriter();
            const reader = res.body.getReader();
            const pump = () => reader.read().then(res => res.done ? window.writer.close() : window.writer.write(res.value).then(pump))
            pump()
        }
        else
            useAlert.salert("下载出错了")


    }).catch(function (err) {

        if (err.response) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { handler_401() }
            }

        }
        //reject (err)
    }).finally(() => {
        //store.commit('hideLoading')
    })


}


function improve(logininfo) {
    return (logininfo.uNumSum == logininfo.uProvCode) ? true : false
}

var myusername = ref("");
var myuserid = ("");
var mytoken = (localStorage.getItem('jwtoken'));
var islogin = ref(false);
var roles = ("")
var realname = ("")

async function myuserlogin(logininfo) {
    if (improve(logininfo)) {
        const memdata = await postapi('firesu/login',
            {
                apiname: logininfo.uAccount,
                data0: logininfo.uPass1
            })
        const getdata = memdata.data;
        if (getdata != "LoginFail") {
            mytoken = (getdata.access_token);
            myusername.value = getdata.user.username;
            localStorage.setItem('logintime', Date())
            localStorage.setItem('jwtoken', getdata.access_token)
            localStorage.setItem('authed', true)
            localStorage.setItem('jwt', getdata.data || '')
            localStorage.setItem('username', getdata.user.username)
            localStorage.setItem('company', getdata.user.company)
            localStorage.setItem('role', getdata.user.roles)
            store.commit('reloadLogin')
            roles = getdata.user.roles;
            realname = getdata.user.realname;
            window.location.href = '/info'
            islogin.value = true;
        }
        else {
            alert("账号密码不正确，登录失败")
            islogin.value = false;
        }
    }
    else
        alert("验证码错误")

}

async function srologin(logininfo) {
    if (improve(logininfo)) {
        const memdata = await postapi('sro/login',
            {
                apiname: logininfo.uAccount,
                data0: logininfo.uPass1
            })
        const getdata = memdata.data;
        if (getdata != "LoginFail") {
            mytoken = (getdata.access_token);
            myusername.value = getdata.user.username;
            localStorage.setItem('logintime', Date())
            localStorage.setItem('jwtoken', getdata.access_token)
            localStorage.setItem('authed', true)
            localStorage.setItem('jwt', getdata.data || '')
            localStorage.setItem('username', getdata.user.username)
            localStorage.setItem('company', getdata.user.company)
            localStorage.setItem('role', getdata.user.roles)
            store.commit('reloadLogin')
            roles = getdata.user.roles;
            realname = getdata.user.realname;
            window.location.href = '/info'
            islogin.value = true;
        }
        else {
            alert("账号密码不正确，登录失败")
            islogin.value = false;
        }
    }
    else
        alert("验证码错误")

}
function sro_regiuser(logininfo) {
    if (logininfo.uPass1 == logininfo.uPass2) {
        postapi('sro/free',
            {
                apiname: 'register_user',
                data0: logininfo.uAccount,
                data1: logininfo.uPass1,
                data2: logininfo.uEmail,
                data3: logininfo.uEmailReply
            }
        ).then(response => {
            alert(response.data)
        })

    }
    else {
        alert("两次密码不一致");
    }
}
async function myuserrelogin() {

    const memdata = await postapi('firesu/relogin',
        {
        })
    const getdata = memdata.data;
    if (getdata != "LoginFail") {
        mytoken = (getdata.access_token);
        myusername.value = getdata.user.username;
        localStorage.setItem('logintime', Date())
        localStorage.setItem('jwtoken', getdata.access_token)
        localStorage.setItem('authed', true)
        localStorage.setItem('jwt', getdata.data || '')
        localStorage.setItem('username', getdata.user.username)
        localStorage.setItem('company', getdata.user.company)
        localStorage.setItem('role', getdata.user.roles)
        store.commit('reloadLogin')
        roles = getdata.user.roles;
        realname = getdata.user.realname;
        islogin.value = true;
        alert("续杯成功啦！")
    }
    else {
        alert("登录失败，请输入账号和密码重新登录")
        islogin.value = false;
    }


}

function generate_improve(logininfo) {
    logininfo.value.uNum1 = Math.ceil(Math.random() * 10);
    logininfo.value.uNum2 = Math.ceil(Math.random() * 10);
    logininfo.value.uNumSum = logininfo.uNum1 * logininfo.uNum2;

}
const range = [{ name: "仅自己可见" }, { name: "小组可见" }, { name: "公司可见" }, { name: "全员可见" }]

function search_item(tag, filter_name, old_data) {
    if (tag && filter_name) {
        var brr = old_data.filter(value => {
            if (value[filter_name])
                return value[filter_name].trim().toLowerCase().includes(tag.trim().toLowerCase())
            else
                return value
        })
        return brr;
    }
    else
        return old_data;
}

function uploadfile_n(api, postdata = {}) {
    mytoken = (localStorage.getItem('jwtoken'));
    return new Promise((resolve) => {
        const requrl = myapiurl +'/'+ api
        axios({
            method: 'post',
            url: requrl,
            headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'multipart/form-data' },
            data: postdata
        }).then(res => {
            resolve(res);
        }).catch(function (err) {
            var errcode = err.response.status;
            switch (errcode) {
                case 401: { warnning_401() }
            }
        })
    }
    )
}

const image_upload = async (blobInfo, progress) => {
    mytoken = (localStorage.getItem('jwtoken'));
    const requrl = myapiurl + "/TinyMCE/Upload";
    const config = {
        onUploadProgress: (e) => {
            progress(Math.round((e.loaded * 100) / e.total));
        },
        headers: { 'Authorization': 'Bearer ' + mytoken, 'Content-Type': 'multipart/form-data' },
    };
    try {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        const response = await axios.post(requrl, formData, config);

        if (response.status === 200 && typeof response.data.location === 'string') {
            return response.data.location;
        } else if (response.status === 403) {
            throw { message: 'HTTP Error: Forbidden', remove: true };
        } else {
            throw new Error(`HTTP Error: ${response.status}`);
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
                throw { message: 'HTTP Error: Forbidden', remove: true };
            } else {
                throw new Error(`Image upload failed due to an Axios error. Code: ${error.response?.status || 'Unknown'}`);
            }
        } else {
            throw new Error('Image upload failed due to an unknown error.');
        }
    }
}


function file_handler(callback, value, meta) {
    console.log(value)
    if (meta.filetype === "media") {
        // 模拟上传本地视频
        let input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", ".mp4");
        input.onchange = function () {
            let file = this.files[0];
            // console.log(file);
            let fd = new FormData();
            fd.append("img", file);
            uploadfile_n("TinyMCE/Upload", fd).then((res) => {
                let rr = res.data;
                console.log(rr.location);
                /* call the callback and populate the Title field with the file name */
                callback(rr.location, { title: file.name });
            });
        };
        input.click();
    }
}


export default {
    getapi, myapiurl, myusername, myuserid, mytoken, islogin, roles, realname,pagename, postapi, updateapi,
    delapi, uploadfile, register_user, get_myode, myuserlogin, generate_improve, improve, getapix, loginout
    , downloadapi, downloadfetch, range, search_item, alert, myuserrelogin, uploadfile_n, jumpto, getbaseapi, image_upload, file_handler,srologin,sro_regiuser
}

